import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import Imagefilm from "../assets/images/imagefilm.jpg";
import Musicvideo from "../assets/images/musicvideo.jpg";
import Aftermovie from "../assets/images/aftermovie.jpg";
import Event from "../assets/images/event.jpg";

export default function CallToAction() {
  const contactRef = useRef(null);

  const socialArr = [
    {
      icon: faFacebook,
      alt: "facebook",
      link: "https://www.facebook.com/fabian.gaukler",
    },
    {
      icon: faInstagram,
      alt: "instagram",
      link: "https://www.instagram.com/fabian_gaukler/",
    },
    {
      icon: faLinkedin,
      alt: "linkedin",
      link: "https://www.linkedin.com/in/fabian-gaukler/",
    },
    {
      icon: faYoutube,
      alt: "youtube",
      link: "https://www.youtube.com/user/0understeer",
    },
  ];

  return (
    <div className="cta">
      <div className="cta-musicvideo">
        <div className="cta-musicvideo-image">
          <img src={Musicvideo} alt=""></img>
        </div>
        <div className="cta-musicvideo-text">
          <div className="cta-musicvideo-text-title">Musikvideos</div>
          <div className="cta-musicvideo-text-main">
            <p>
              Ein Musikvideo ist die Summe unberechenbarer kreativer Gefühle,
              die unabhängig von der realen Welt passieren.
            </p>
            <p>Ich setze mir keine Grenzen. Alles ist möglich.</p>
          </div>
          <button
            className="light"
            onClick={() => contactRef.current.scrollIntoView()}
          >
            Preis auf Anfrage
          </button>
        </div>
      </div>
      <div className="cta-event">
        <div className="cta-event-image">
          <img src={Event} alt=""></img>
        </div>
        <div className="cta-event-text">
          <div className="cta-event-text-title">Event-Fotografie</div>
          <div className="cta-event-text-main">
            <p>Ihr Unternehmen veranstaltet ein wichtiges Event?</p>
            <p>
              Ich halte besondere Blickwinkel fest und sorge dafür, dass die
              Veranstaltung in ein besonderes Licht gerückt wird.
            </p>
            ​<p>Hochwertige Bilder, welche den Tag unvergessen machen!</p>
          </div>
          <button
            className="light"
            onClick={() => contactRef.current.scrollIntoView()}
          >
            Preis auf Anfrage
          </button>
        </div>
      </div>
      <div className="cta-aftermovie">
        <div className="cta-aftermovie-image">
          <img src={Aftermovie} alt=""></img>
        </div>
        <div className="cta-aftermovie-text">
          <div className="cta-aftermovie-text-title">Aftermovies</div>
          <div className="cta-aftermovie-text-main">
            <p>Es steht ein besonderes Event an?</p>
            <p>
              Ich verfilme eure Emotionen und Highlights und lasse besondere
              Momente in bester Filmqualität verewigen.
            </p>
            ​<p>Video-Content wird zum größten und wichtigsten Medium!</p>
          </div>
          <button
            className="light"
            onClick={() => contactRef.current.scrollIntoView()}
          >
            Preis auf Anfrage
          </button>
        </div>
      </div>
      <div className="cta-imagefilm">
        <div className="cta-imagefilm-image">
          <img src={Imagefilm} alt=""></img>
        </div>
        <div className="cta-imagefilm-text">
          <div className="cta-imagefilm-text-title">Imagefilme</div>
          <div className="cta-imagefilm-text-main">
            <p>Jedes Unternehmen hat eine Vision.</p>
            <p>
              Die Kunden verbinden einzigartige Gefühle mit Ihren Produkten oder
              Dienstleistungen. Genau dieses Gefühl lassen ich in meinen
              Imagefilmen aufleben.
            </p>
            <p>Ich will Kunstwerke schaffen.</p>
          </div>
          <button
            className="dark"
            onClick={() => contactRef.current.scrollIntoView()}
          >
            Preis auf Anfrage
          </button>
        </div>
      </div>
      <div className="cta-social">
        {socialArr.map((item, index) => (
          <a
            href={item.link}
            key={index}
            className={`cta-social-icon cta-social-icon-${item.alt}`}
          >
            <FontAwesomeIcon icon={item.icon} />
          </a>
        ))}
      </div>
      <div className="cta-contact" ref={contactRef}>
        <div className="cta-contact-text">
          Wenn du Interesse an Filmen, Bildern oder Webseiten hast, schreib mir
          doch gerne eine Mail:
        </div>
        <a href="mailto:info@fabiangaukler.de">
          <div className="cta-contact-mail">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
        </a>
      </div>
    </div>
  );
}
