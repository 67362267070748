import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReact } from "@fortawesome/free-brands-svg-icons";
import TextLoop from "react-text-loop";

import ProfilePicture from "../assets/images/profile.jpg";
import { faCameraRetro, faFilm } from "@fortawesome/free-solid-svg-icons";

export default function Intro() {
  return (
    <div className="intro">
      <div className="intro-image">
        <img src={ProfilePicture} alt="profile"></img>
      </div>
      <div className="intro-name">Hi, ich bin Fabian:</div>
      <div className="intro-text">
        <TextLoop>
          <span>
            <FontAwesomeIcon icon={faReact} /> Frontend Web Developer
          </span>
          <span>
            <FontAwesomeIcon icon={faFilm} /> Filmproduzent
          </span>
          <span>
            <FontAwesomeIcon icon={faCameraRetro} /> Fotograf
          </span>
        </TextLoop>
      </div>
    </div>
  );
}
