import React from "react";
import dayy from "../assets/images/dayy.svg";
import ifas from "../assets/images/ifas.png";
import nrml from "../assets/images/nrml.png";
import pixelarbeiter from "../assets/images/pixelarbeiter.png";
import rbmh from "../assets/images/rbmh.svg";
import umweltcampus from "../assets/images/umwelt-campus.svg";
import zeroundersteer from "../assets/images/zeroundersteer.png";
import rennwest from "../assets/images/renn-west.svg";
import ergosign from "../assets/images/ergosign.png";
import dalarna from "../assets/images/dalarna.jpg";
import chemie from "../assets/images/chemieverbaende.svg";
import pexapark from "../assets/images/pexapark.png";

export default function References() {
  const referencesArr = [
    {
      url: rbmh,
      alt: "rbmh",
      link: "https://www.redbullmediahouse.com/en",
      size: "sm",
    },
    { url: ergosign, alt: "ergosign", link: "https://www.ergosign.de/" },
    { url: dayy, alt: "dayy", link: "https://www.dayy.de/", size: "sm" },
    {
      url: umweltcampus,
      alt: "umweltcampus",
      link: "https://www.umwelt-campus.de/",
    },
    { url: dalarna, alt: "dalarna", link: "https://www.du.se/" },
    {
      url: zeroundersteer,
      alt: "zeroundersteer",
      link: "https://www.youtube.com/user/0understeer",
    },
    { url: chemie, alt: "chemieverbaende", link: "https://www.chemie-rp.de/" },
    {
      url: rennwest,
      alt: "rennwest",
      link: "https://www.renn-netzwerk.de/west",
    },
    { url: pexapark, alt: "pexapark", link: "https://pexapark.com/" },
    { url: nrml, alt: "nrml", link: "https://nrml.de/" },
    {
      url: pixelarbeiter,
      alt: "pixelarbeiter",
      link: "https://pixelarbeiter.de/",
    },
    { url: ifas, alt: "ifas", link: "https://www.stoffstrom.org/" },
  ];
  return (
    <div className="references">
      <div className="references-title">Meine Referenzen</div>
      <div className="references-list">
        {referencesArr.map((item, index) => {
          return (
            <div
              className={`references-list-item ${
                item.size === "sm" ? "references-list-item-sm" : ""
              }`}
              key={index}
            >
              <a href={item.link} target="_blank" rel="noreferrer">
                <img src={item.url} alt={item.alt}></img>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
