import React, { useState } from "react";
import Datenschutz from "./Datenschutz";
import Impressum from "./Impressum";

export default function Footer() {
  const [isImpressumVisible, setIsImpressumVisible] = useState(false);
  const [isDatenschutzVisible, setIsDatenschutzVisible] = useState(false);

  return (
    <>
      <div className="footer">
        <div className="footer-left">
          &copy; Fabian Gaukler {new Date().getFullYear()}
        </div>
        <div className="footer-right">
          <span onClick={() => setIsImpressumVisible(true)}>Impressum</span>
          <span>&ensp;|&ensp;</span>
          <span onClick={() => setIsDatenschutzVisible(true)}>Datenschutz</span>
        </div>
      </div>
      {isImpressumVisible && (
        <Impressum setIsImpressumVisible={setIsImpressumVisible} />
      )}
      {isDatenschutzVisible && (
        <Datenschutz setIsDatenschutzVisible={setIsDatenschutzVisible} />
      )}
    </>
  );
}
