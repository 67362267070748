import React from "react";
import Intro from "./components/Intro";
import References from "./components/References";
import ShowcaseFilmmaking from "./components/ShowcaseFilmmaking";
import CallToAction from "./components/CallToAction"
import Footer from "./components/Footer";

export default function App() {
  return (
    <>
      <Intro />
      <ShowcaseFilmmaking />
      <References />
      <CallToAction />
      <Footer />
    </>
  );
}
