import React from "react";

import Showreelmp4 from "../assets/videos/showreel.mp4";
import Showreelwebm from "../assets/videos/showreel.webm";

export default function ShowcaseFilmmaking() {
  return (
    <div className="showcase-filmmaking">
      <video autoPlay muted loop>
        <source
          src={Showreelmp4}
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
        <source src={Showreelwebm} type='video/webm; codecs="vp8, vorbis"' />
      </video>
    </div>
  );
}
